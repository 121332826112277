import React, { useState } from "react"
import {
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react"

import { useForm } from "react-hook-form"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur'
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const onSubmit = data => {
    
    setProcessing(true);

    fetch('https://g2m2i20dkl.execute-api.us-west-2.amazonaws.com/production/contact-vard', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
        },            
            body: JSON.stringify(data)
    })
    .then((response) => {
        if (response.status === 200) {
            console.log(JSON.stringify(data));
            setSucceeded(true);
            reset();
        } else {
            console.log('network error')
        }
    })

  }

  const SuccessMessage = () => (
      <Box>      
          <Heading as="h3" fontSize="1.75rem" color="brand.400" w="100%" style={{textAlign: 'center'}}>Thank you for your message.<br />
          We'll be in touch soon!</Heading>
      </Box>
  )


  return (
    <>
      <Box w="100%">
      { succeeded ? <SuccessMessage /> : (
        <>
        <Heading as="h3" fontSize="xl" mb={4}>Send us a message</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box w="100%" mb={4}>
              <FormControl id="name">
                <FormLabel>Your Name<Text color="sunset.600" as="span">*</Text></FormLabel>
                <Input type="text"
                  borderColor="steel.400"
                  focusBorderColor="steel.700"
                  errorBorderColor="sunset.600"
                    {...register("name", {required: true} )}
                />
                {errors.name && <Text color="sunset.600">Please enter your name.</Text>}
              </FormControl>
            </Box>
            <Box w="100%" mb={4}>
              <FormControl id="phone">
                <FormLabel>Phone Number</FormLabel>
                <Input type="tel"
                borderColor="steel.400"
                focusBorderColor="steel.700"
                    {...register("phone")}
                />
                {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              </FormControl>
            </Box>
            <Box w="100%" mb={4}>
              <FormControl id="email">
                <FormLabel>Email address<Text color="sunset.600" as="span">*</Text></FormLabel>
                <Input type="email" 
                borderColor="steel.400"
                focusBorderColor="steel.700"
                errorBorderColor="sunset.600"
                   {...register("email", 
                    {
                      required: true, 
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                     }
                  }
                  )}
                   />
                   {errors.email && <Text color="sunset.600">Please enter a valid email address.</Text>}
              </FormControl>
            </Box>
          
          <Box>
        <FormControl id="message" mb={4}>
          <FormLabel>Your Message<Text color="sunset.600" as="span">*</Text></FormLabel>
            <Textarea placeholder=""
            borderColor="steel.400"
            focusBorderColor="steel.700"
            errorBorderColor="sunset.600"
                {...register("message", {required: true} )}
                />
                {errors.message && <Text color="sunset.600">Please enter a message.</Text>}
            </FormControl>
            </Box>
          <Box mt="4">
          <Button 
            colorScheme="steel"
            type="submit" mt={4} >
              {processing ? "Submitting..." : "Submit" }
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default ContactForm
